var site = site || {};

site.device = {};
site.window = {};
site.owl = {};
site.nav = {};
site.sidebar = {};
site.hero = {};
site.search = {};

site.device.isTouch = function() {
	try {
		document.createEvent("TouchEvent");
		return true;
	} catch (e) {
		return false;
	}
};

site.window.scrollPosition = 0;

// Shorthand to init owlcarousel
site.owl.init = function(id, options) {
	var $owl = $(id);
	if ($owl.length > 0) {
		$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
		$owl.owlCarousel(options);
	}
};

// Init navigation scripts
site.nav.init = function() {
	// Add expand link to mobile menu
	$('.children', '#mainmenu ').prepend('<a class="expand" href="#"><i class="fa fa-angle-down"></i></a>');
	$('.selectedLevel1','#mainmenu').addClass('down');
	$('.selectedLevel2','#mainmenu').addClass('down');
	$('.selectedLevel3','#mainmenu').addClass('down');
	site.nav.bind();

	$('.level1.children', '#mainmenu').click(function(e) {
		if (!$(this).hasClass('dropdown')) {
			e.preventDefault();
		}
		$(this).toggleClass('dropdown');
		$('body').toggleClass('dropdown');

		$('body.dropdown').on('click', function (e) {
			if (!$(e.target).parents('#mainmenu').length) {
				$('body').removeClass('dropdown');
				$('.dropdown','#mainmenu').removeClass('dropdown');
			}
		});
	});
};

// Bind events
site.nav.bind = function() {
	// Mobile menu toggle
	$('#mainmenuToggle').click(function(e) {
		var menuOpen = $('body').hasClass('mainmenu-open');
		if (!menuOpen) {
			site.window.scrollPosition = $(window).scrollTop();
		}
		site.nav.mobileToggle(e, menuOpen, site.window.scrollPosition);
		e.preventDefault();
	});

	// Expand in mobile menu
	$('.expand', '#mainmenu').bind('click', function(e) {
		e.preventDefault();

		var $this = $(this);
		$this.closest('li').toggleClass('down');
	});
};

// Toggle mobile menu
site.nav.mobileToggle = function(e, menuOpen, scrollPosition) {
	$('body').toggleClass('mainmenu-open');
	$(this).toggleClass('open');
	if (!menuOpen) {
		$(window).scrollTop(0);
		$('i', '#mainmenuToggle').removeClass('fa-bars').addClass('fa-close');
	}
	else {
		$(window).scrollTop(scrollPosition);
		$('i', '#mainmenuToggle').removeClass('fa-close').addClass('fa-bars');
	}
};

// Append language to mobilemenu
site.nav.appendLanguage = function() {
	var $divisions = $('.menu', '#divisions');
	var $menu = $('ul.mmLevel1', '#mMenu');

	var $html = $('<li class="ele-language si level1"></li>');
	$divisions.find('li').each(function() {
		var $li = $(this).clone();
		$html.append($li.find('a'));
	});

	$menu.append($html);
};

site.search.init = function () {
	$('#searchToggle').on('click', function (e) {
		e.preventDefault();
		$('body').toggleClass('search-open');

		if ($('body').hasClass('search-open')) {
			$('i', '#searchToggle').removeClass('fa-search').addClass('fa-close');
			$('#q').focus();
		}
		else {
			$('i', '#searchToggle').removeClass('fa-close').addClass('fa-search');
		}
	});
};


// Run all the inits \o/
$(function() {
	site.nav.init();
	site.search.init();

	// Responsive vimeo player
	//var $i = $('iframe', '#center');
	//if ($i.length > 0 && $i.attr('src').indexOf('player.vimeo.com') != -1) {
	//	$i.wrap('<div class="vimeoPlayer"></div>');
	//}

	// Responsive tables
	$('table', '#main').wrap('<div class="responsiveTable"></div>');

	// Touch login
	$('.login').bind('click', function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	// Reorder language menu based on selected
	// Move is to the top
	$li = $('.selected', '#divisions');
	$ul = $('.menu', '#divisions');
	$ul.prepend($li);

	$(".scroll").click(function(event){
		event.preventDefault();
		$('html,body').animate({scrollTop:$(this.hash).offset().top},1200);
	});
});
